import { useCallback, useSyncExternalStore } from "react";
let last = Date.now(), now = ()=>globalThis.window ? last : Date.now();
export function useCurrentTime(updateInterval) {
    return void 0 === updateInterval && (updateInterval = 1000), useSyncExternalStore(useCallback((cb)=>{
        let handler = setInterval(()=>{
            last = Date.now(), cb();
        }, updateInterval);
        return ()=>clearInterval(handler);
    }, [
        updateInterval
    ]), now, now);
}
